<template>
  <div class="con">
    <backHeader></backHeader>
    <div class="news-detail">
        <div>
          <h2 class="title">{{rtext.title}}</h2>
          <div class="desp">概要：{{rtext.resume}}</div>
          <div class="rich-text" v-html="rtext.content">
            
          </div>
        </div>
    </div>    
    <back-top></back-top>
    <d-footer ></d-footer>
   </div>
</template>

<script>
import { ref,onMounted,watch,computed} from 'vue'
// import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'

import backTop  from '@/components/backTop.vue'
import backHeader  from '@/components/backHeader.vue'
import DFooter from '../index/components/DFooter.vue'
import DTitle from '@/components/DTitle.vue'
import service from '../../service.js';

import {useScrollToParamsId} from '@/use/diy-use.js'


export default {
  name: 'news',
  components:{backTop,DFooter,backHeader,DTitle},
  setup(){
    const route = useRoute();
    const router = useRouter();
    const rtext = ref([]);

    const id =route.params.id;
    const goto = (id)=>{
      router.push({name:'detail',params:{id:id}});
    }

    switch(id*1) {
     case 1:
        document.title="首期增材制造技术职业教育师资培训班完美收官_粤增减材";
        break;
     case 2:
        document.title="首期增材制造技术职业教育师资培训班扬帆起航_粤增减材";
        break;
     case 3:
        document.title="增材制造重塑“快速原型”或将彻底改变制造业格局_粤增减材";
        break;
     case 4:
        document.title="增材制造应用篇——工业装备制造_粤增减材";
        break;
     case 5:
        document.title="央视《品质》栏目组采访粤增减材_粤增减材";
        break;
     case 6:
        document.title="关于召开增材制造专业建设与1+X“增材制造设备操作与维护”职业技能等级标准研讨会的通知_粤增减材";
        break;
     default:
        document.title="粤增减材";
    } 

    service({url:"/api/news",method: 'get',params:{action:"show",id:id}})
    .then(res=>{
      rtext.value = res.retdata.list;
    });

    console.log(id);

    return {goto,rtext}
  }
}
</script>

<style scoped>
/*.con{padding-top:64px; background: #f1f1f1; overflow: hidden;}*/

.news-detail{width:1000px; margin:auto; background: #fff; padding:64px 25px 10px 25px; line-height: 2;  min-height: calc(100vh - 120px);}
.news-detail h2.title{font-size:21px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:20px 0 10px 0; border-bottom:1px solid #eee;}
.news-detail h3{font-size:18px; color:#333; font-weight: bold; padding:5px 0; margin:15px 0 0px 0; }
.news-detail h4{font-size:16px; color:#666; font-weight: bold; padding:5px 0; margin:15px 0 0px 0; }


.news-detail div.desp{font-size:14px; line-height: 1.9; text-indent:2em; color:#333; margin-bottom:20px; color:#999; text-indent:0em;}

.news-detail .ultitle{font-size:16px; color:#333; font-weight: bold; margin-top:15px;}
.news-detail ul{list-style-type: disc; padding-left:20px; font-size:14px;}
.news-detail ul li{line-height:1.9; color:#999;}

.rich-text img{width:700px;}
@media screen and (max-width:800px) {
.news-detail{width:auto; margin:34px auto 40px auto; min-height: calc(100vh - 90px);}
.news-detail h2.title{text-align: left; line-height: 1.2;}
.news-detail .leftbox{display:none;}
.news-detail{margin-left:0px; padding:10px 10px;}
.news-detail .img-box img{width: 374px;}
}
</style>

<style type="text/css">
.news-detail .rich-text p{font-size:14px; line-height: 1.9; color:#333;}
.news-detail .rich-text p img{width:800px; margin:5px 0;}
@media screen and (max-width:800px) {
/*.news-detail .rich-text p{font-size:14px; line-height: 1.9; color:#333;}*/
.news-detail .rich-text p img{width:374px;}

}
</style>
